import React, { useState, useEffect } from "react";
import angleUpWhite from "../../static/angle-up-sapphire.svg";
import { StoryLane } from "src/components/commonComponents/storyLane";
import { LocalizedLink } from "src/components/commonComponents";
import angleDownSapphire from '../../static/angle-down-sapphire.svg';
import { FormModal } from "src/components/commonComponents";
import useMarketoForm from "src/components/helpers/useMarketoForm";
import extractMarketoValues from "src/components/helpers/extractMarketoIdAndSerial";
import angleRight from "../../static/angle-right-black.svg";
import angleLeft from "../../static/angle-left.svg";
import angleRightSapphire from "../../static/angle-right-sapphire.svg";
import { StaticImage } from "gatsby-plugin-image";
import { ResourceTagsList } from "src/components/templateComponents";
import { SEO } from "src/components/commonComponents";
import getOGMarkup from "src/components/helpers/getOGMarkup";

import AoemColor from "../../src/images/hackathon/logos/color/Logo-AOEM-color.png";
import DataInsightsColor from "../../src/images/hackathon/logos/color/Logo-DataInsights-color.png";
import EmsColor from "../../src/images/hackathon/logos/color/Logo-EMS-color.png";
import LucernexColor from "../../src/images/hackathon/logos/color/Logo-Lucernex-color.png";
import LxColor from "../../src/images/hackathon/logos/color/Logo-LxTransaction-color.png";
import McColor from "../../src/images/hackathon/logos/color/Logo-MC-color.png";
import RedEyeColor from "../../src/images/hackathon/logos/color/Logo-Redeye-color.png";
import SiterraLogo from "../../src/images/hackathon/logos/color/Logo-Siterra-color.png";
import VXObserveColor from "../../src/images/hackathon/logos/color/Logo-VXObserve-color.png";
import asiColor from "../../src/images/hackathon/logos/color/Logo-ASI-Color.png";
import TMSColor from "../../src/images/hackathon/logos/color/Accruent-TMS-Color.png";


import AoemGray from "../../src/images/hackathon/logos/gray/Logo-AOEM-gray.png";
import DataInsightsGray from "../../src/images/hackathon/logos/gray/Logo-DataInsights-gray.png";
import EmsGray from "../../src/images/hackathon/logos/gray/Logo-EMS-gray.png";
import LucernexGray from "../../src/images/hackathon/logos/gray/Logo-Lucernex-gray.png";
import LxGray from "../../src/images/hackathon/logos/gray/Logo-LxTransaction-gray.png";
import McGray from "../../src/images/hackathon/logos/gray/Logo-MC-gray.png";
import RedEyeGray from "../../src/images/hackathon/logos/gray/RedEye-Accruent-gray.png";
import SiterraLogoGray from "../../src/images/hackathon/logos/gray/Logo-Siterra-gray.png";
import VXObserveGray from "../../src/images/hackathon/logos/gray/Logo-VXObserve-gray.png";
import asiGray from "../../src/images/hackathon/logos/gray/Logo-ASI-gray.png";
import TMSGray from "../../src/images/hackathon/logos/gray/Accruent-TMS-gray.png";

import backgroundDotImage from '../../src/images/hackathon/background-image.png';

const DemoNew = () => {
  const fieldEmbedCode = "<form id=\"mktoForm_4600\"></form>\r\n<script>MktoForms2.loadForm(\"//info.accruent.com\", \"167-BOY-362\", 4600, function(form) {\r\n    dataLayer.push({\r\n      'event': 'gaTriggerEvent',\r\n      'gaCategory': 'CTA Engagement',\r\n      'gaAction': 'Form Load',\r\n      'gaLabel': 'Demo Request',\r\n    });\r\n    form.onSuccess(function(values, followUpUrl) {\r\n      dataLayer.push({\r\n        'event': 'gaTriggerEvent',\r\n        'gaCategory': 'CTA Engagement',\r\n        'gaAction': 'Form Submission',\r\n        'gaLabel': 'Demo Request',\r\n      });\r\n    });\r\n  });\r\n</script>"
  const [active, setActive] = useState(0);
  const [showModal, setShowModal] = useState(false);
  const [hoverState, setHoverState] = useState(0);
  const [openAISuggestedProducts, setOpenAISuggestedProducts] = useState([]);
  const [apiCall, setApiCall] = useState(false);
  const handleToggle = () => {
    setShowModal(!showModal);
  };
  const { id, serial, gaLabel } = extractMarketoValues(
    fieldEmbedCode
  );
  const [isFormLoaded, setFormLoaded] = useState(false)
  const formCallback = () => {
    setFormLoaded(true)
  }
  const { formTag } = useMarketoForm(serial, id, formCallback, "", gaLabel);
  const scrollMethod = (condition) => {
    let id = document.getElementById('products-scroll')
    id.scrollBy({
      top: condition === "down" ? 150 : condition === "up" ? -150 : '',
      left: condition === "right" ? 200 : condition === "left" ? -200 : '',
      behavior: "smooth",
    })
  }

  const OGMarkup = getOGMarkup("New Demo Page");

  const getProductListViaOpenAI = async (industries) => {
    try {
      if (industries?.length && industries?.length > 0) {
        const controller = new AbortController();
        const timeoutId = setTimeout(() => controller.abort(), 5000);

        const openAIResp = await fetch("/.netlify/functions/recommendedProducts", {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify(
            {
              "industries": industries
            }
          ),
        });
        clearTimeout(timeoutId);
        const openAIData = await openAIResp.json()
        try {
          setOpenAISuggestedProducts(openAIData)
          localStorage.setItem("OPENAI_PRODUCTS_RECOMMENDATIONS", JSON.stringify(openAIData))
          setApiCall(true);
        } catch (error) {
          console.log(error)
          getAllProducts()
        }
      } else {
        getAllProducts()
      }

    }
    catch (error) {
      getAllProducts()
      console.log(error)
    }
  }

  const getAllProducts = () => {
    setOpenAISuggestedProducts([])
    localStorage.setItem("OPENAI_PRODUCTS_RECOMMENDATIONS", JSON.stringify([]))
    localStorage.setItem("INDUSTRIES", JSON.stringify([]))
    setApiCall(true);
  }

  useEffect(() => {
    const getUserIndustries = async () => {
      let industries = []
      try {
        //Fetch IP Address
        const res = await fetch('https://api64.ipify.org?format=json')
        const result = await res.json()
        const ip = result.ip

        //Check JWT expiry
        let currentRequestTime = Date.now()
        let lastRequestTime = parseInt(localStorage.getItem("zi-lastRequestTime"))
        if(!lastRequestTime || (lastRequestTime && currentRequestTime - lastRequestTime > 55 * 60 * 1000)) {
          let jwtResp = await fetch("/.netlify/functions/getJwtToken", {
            method: "POST",
            headers: {
              "Content-Type": "application/json",
            },
          })
          let jwtJson = await jwtResp.json()
          localStorage.setItem("zi-jwt", jwtJson.jwt)
          localStorage.setItem('zi-lastRequestTime', currentRequestTime)
        }

        //Fetch Industries
        const enrichResp = await fetch("https://api.zoominfo.com/enrich/ip", {
          method: "POST",
          headers: {
            "Authorization": `Bearer ${localStorage.getItem("zi-jwt")}`,
            "Content-Type": "application/json",
          },
          body: JSON.stringify({
            "ipAddress": ip
          }),
        });
        const enrichData = await enrichResp.json()
        if (enrichData?.data?.result[0]?.data?.company?.industries) {
          industries = enrichData.data.result[0].data.company.industries
          console.log("Industries Found", industries)
        }

        //=====================Get recommendations=========
        let industriesFromLocalStorage = localStorage?.getItem("INDUSTRIES");
        if (industriesFromLocalStorage !== JSON.stringify(industries)) {
          localStorage.setItem("INDUSTRIES", JSON.stringify(industries))
          getProductListViaOpenAI(industries)
        } else {
          const currentOpenIdProducts = JSON.parse(localStorage.getItem("OPENAI_PRODUCTS_RECOMMENDATIONS"))
          if (currentOpenIdProducts) {
            setOpenAISuggestedProducts(currentOpenIdProducts);
          } else {
            getProductListViaOpenAI(industries);
          }
          setApiCall(true);
        }
      }
      catch (e) {
        console.log(e)
        getAllProducts()
      }
    }
    //getUserIndustries();
    getAllProducts()
  }, [])

  let data = [
    {
      image: McColor,
      greyImage: McGray,
      storyLane: {
        storyLaneUrl: "https://app.storylane.io/demo/fmso7etcmpq7"
      },
      ctaText: 'Computerized Maintenance Management System (CMMS)',
      iframeText: "Help your maintenance teams transition from reactive to preventive maintenance, extend asset life, optimize operations, and ensure compliance with our industry-leading CMMS/EAM.",
      reviews: [
        {
          anchorLink: 'https://www.g2.com/products/accruent-maintenance-connection/reviews?utm_source=review-widget',
          imageLink: 'https://www.g2.com/products/accruent-maintenance-connection/widgets/stars?color=white&amp;type=read',
          alt: 'Read Accruent Maintenance Connection reviews on G2'
        },
        {
          anchorLink: 'https://www.capterra.com/p/12444/Maintenance-Connection/reviews?utm_source=vendor&utm_medium=badge&utm_campaign=capterra_reviews_badge',
          imageLink: 'https://assets.capterra.com/badge/4e94a1750afa0d00b41e5247fb6fd300.svg?v=2004044&p=12444',
          alt: 'Read Accruent Maintenance Connection reviews on G2'
        }
      ],
      productId: 'MC',
      tags: [
        {
          label: 'Manufacturing'
        },
        {
          label: 'Oil & Gas'
        },
        {
          label: 'Food & Bev'
        }
      ]
    },
    {
      image: EmsColor,
      greyImage: EmsGray,
      storyLane: {
        storyLaneUrl: "https://app.storylane.io/demo/rz4urqyycf6c"
      },
      ctaText: 'Space Management & Room Scheduling Software',
      iframeText: 'Simplify desk and room booking, event management, and class & exam scheduling with EMS workplace management software.',
      reviews: [
        {
          anchorLink: 'https://www.capterra.com/p/232490/EMS-Scheduling-Software/reviews?utm_source=vendor&utm_medium=badge&utm_campaign=capterra_reviews_badge',
          imageLink: 'https://assets.capterra.com/badge/edf2bb5331f8c7a9e02fdc10720194c9.svg?v=2014805&p=88249',
          alt: ''
        }
      ],
      productId: 'EMS',
      tags: [
        {
          label: 'Corporate Real Estate'
        },
        {
          label: 'Higher Education '
        },
        {
          label: 'Public Sector'
        }
      ]
    },
    {
      image: RedEyeColor,
      greyImage: RedEyeGray,
      alt: 'red-eye',
      coloresllImage: '',
      storyLane: {
        storyLaneUrl: "https://app.storylane.io/demo/xgczbdueasqv"
      },
      ctaText: 'Engineering Drawing Management Software',
      iframeText: "Reduce costs, maintain aging infrastructure, and maximize employee safety with Redeye's searchable engineering drawings & asset data.",
      productId: 'RedEye',
      tags: [
        {
          label: 'Power'
        },
        {
          label: 'Water'
        },
        {
          label: 'Mining'
        }
      ]
    },
    {
      image: LucernexColor,
      greyImage: LucernexGray,
      storyLane: {
        storyLaneUrl: "https://app.storylane.io/demo/8gg34lf7sfon"
      },
      ctaText: 'Commercial Lease Management, Site Selection/Planning, and Construction Project Management Software',
      iframeText: "Easily maintain compliance, simplify project management, and optimize your real estate portfolio with Lucernex real estate management software.",
      reviews: [
        {
          anchorLink: 'https://www.softwareadvice.com/property/lucernex-profile/reviews/',
          imageLink: 'https://badges.softwareadvice.com/reviews/13b0483f-9b5d-44ac-be1d-a6d200b5007d',
          alt: ''
        },
        {
          anchorLink: 'https://www.g2.com/products/accruent-accruent-lucernex/reviews?utm_source=review-widget',
          imageLink: 'https://www.g2.com/products/accruent-accruent-lucernex/widgets/stars?color=white&amp;type=read',
          alt: ''
        }
      ],
      productId: 'Lucernex',
      tags: [
        {
          label: 'Corporate Real Estate'
        },
        {
          label: 'Retail'
        }
      ]
    },
    {
      image: AoemColor,
      greyImage: AoemGray,
      alt: 'red-eye',
      storyLane: {
        storyLaneUrl: "https://app.storylane.io/demo/7t4hbz2q3bbi"
      },
      ctaText: 'Energy Management Software ',
      iframeText: "Effectively manage risk, reach energy consumption targets, lower costs, and meet carbon-neutral goals in your plants and buildings with AOEM. ",
      productId: 'AOEM',
      tags: [
        {
          label: 'Corporate Real Estate'
        },
        {
          label: 'Retail'
        },
        {
          label: 'Manufacturing'
        }
      ]
    },
    {
      image: asiColor,
      greyImage: asiGray,
      maxHeight: 40,
      paddingTopBottom: 16,
      storyLane: {
        storyLaneUrl: "https://app.storylane.io/demo/laiz8opqdzc2"
      },
      ctaText: 'An Integrated Space Management Software',
      iframeText: "Optimize space utilization, control costs, eliminate redundant systems, and create a delightful workplace with our comprehensive space management software.",
      tags: [
        {
          label: 'Space Management'
        }
      ],
      productId: 'Accruent Space Intelligence'
    },
    {
      image: TMSColor,
      greyImage: TMSGray,
      maxHeight: 40,
      storyLane: {
        storyLaneUrl: "https://app.storylane.io/demo/wgx8n0jctgod"
      },
      ctaText: 'Healthcare CMMS Software for Maximum Flexibility',
      iframeText: "With Accruent’s TMS, CMMS software for healthcare, you can maximize cybersecurity, create a more connected workplace, and improve regulatory compliance while optimizing biomed and facility management operations.",
      productId: 'TMS',
      tags: [
        {
          label: 'Healthcare'
        },
        {
          label: 'Facility Management'
        }
      ]
    },
    {
      image: DataInsightsColor,
      greyImage: DataInsightsGray,
      storyLane: {
        storyLaneUrl: "https://app.storylane.io/demo/26yz3dqmjagx"
      },
      ctaText: 'Medical Equipment Planning Software',
      iframeText: "Make the right medical equipment purchasing decisions to stretch budgets and maximize patient safety.",
      productId: 'Data Insights',
      tags: [
        {
          label: 'Healthcare'
        },
        {
          label: 'Pharmaceuticals '
        }
      ]
    },

    {
      image: SiterraLogo,
      greyImage: SiterraLogoGray,
      storyLane: {
        storyLaneUrl: "https://app.storylane.io/demo/fwukgdyu5cjl"
      },
      ctaText: 'Telecom Site Management Software',
      iframeText: "Streamline telecom projects, reduce costs, and improve accountability in an all-in-one, enterprise-grade telecom site management software.",
      tags: [
        {
          label: 'Telecommunications'
        },
        {
          label: 'Renewable Energy'
        },
        {
          label: 'EV Charging'
        }
      ],
      productId: 'Siterra'
    },
    {
      image: VXObserveColor,
      greyImage: VXObserveGray,
      storyLane: {
        storyLaneUrl: "https://app.storylane.io/demo/za9hwqg1spci"
      },
      ctaText: 'IoT Remote Monitoring and Energy Management System',
      iframeText: "Improve equipment performance, predict asset failures before they occur, and facilitate remote operations vx Observe energy management and IoT remote monitoring.",
      tags: [
        {
          label: 'Retail'
        },
        {
          label: 'Food & Beverage'
        },
        {
          label: 'Corporate Real Estate'
        }
      ],
      productId: 'Observe'
    },
  ]
  let parsedData = data.filter(x => openAISuggestedProducts?.includes(x?.productId))
  data = (parsedData?.length && parsedData?.length > 0) ? parsedData : data

  const RequestDemoBtn = ({ setShowModal }) => {
    return (
      <div className=" tw-whitespace-nowrap tw-bg-[#485FC7] tw-p-[12px_24px] tw-rounded-[12px] tw-cursor-pointer hover:tw-bg-accruent_sapphire" onClick={() => setShowModal(true)}>
        <button className="tw-text-[#FFFFFF] tw-text-[16px] tw-font-bold tw-font-Poppins ">Request Live Demo</button>
      </div>
    )
  }

  return (
    <div>
      <SEO
        meta={[{ 'name': 'robots', 'content': 'noindex' }]}
        pageUrl="/demo-new"
        OGMarkup={OGMarkup}
      />

      <div className="event-overview-page new-demo-page tw-font-Roboto tw-mb-[76px]">
        {!apiCall ? <div className="container !tw-mt-[250px] tw-flex tw-justify-center"><div><p className="subtitle-1">Hang tight! We are personalizing your experience...</p></div></div> : <div className="container">
          <div className="header-wrapper tw-mt-[32px] md:tw-mt-[52px] tw-relative">
            <div className="tw-mb-[40px]">
              <div className="eyebrow tw-text-[12px] tw-mb-[8px] tw-text-accruent_base_color">NEW</div>
              <h1 className="tw-text-xxxlFont md:tw-text-headingFont tw-mb-[16px] tw-ml-0 tw-transform-none tw-font-Poppins">Interactive Demos</h1>
              <p className="md:tw-w-3/4 tw-text-[22px] tw-leading-[21.6px] tw-w-full  tw-text-accruent_black_olive tw-font-Roboto tw-font-medium">Take a stroll through our software offerings with these interactive demos and learn how Accruent can help your business.</p>
              {data?.[active]?.tags?.length && <ResourceTagsList ResourceTags={data?.[active]?.tags} noUrl={true} />}
            </div>
          </div>
          <div className="iframe-section tw-flex tw-flex-col md:tw-flex-row tw-gap-3 tw-relative tw-mb-[79px]">
            <div className="background-image tw-hidden md:tw-block"> <img src={backgroundDotImage}
            /></div>
            <div className=" tw-bg-[#F8F8F8] tw-rounded-[24px] tw-z-10" onMouseLeave={() => setHoverState(active)}>
              <div className="tw-p-[26px_16px_16px_16px] md:tw-p-[26px_16px_0px_16px] tw-flex tw-flex-col tw-min-w-[278px] tw-text-center">
                <p className="tw-font-bold tw-text-accruent-heading-color tw-text-[14px] tw-leading-[23.2px] tw-tracking-[0.13px] tw-font-Poppins">SELECT A PRODUCT</p>
                {data?.length > 6 &&
                  <img onClick={() => scrollMethod('up')} src={angleUpWhite} className="md:tw-block tw-hidden tw-cursor-pointer svg-inline--fa fa-angle-up angle-icon tw-angle-icon tw-max-h-[15px]" decoding='async' />}
                <div className="tw-flex md:tw-block tw-items-center tw-gap-5">
                  <img className="md:tw-hidden tw-max-h-[15px]" src={angleLeft} onClick={() => scrollMethod('left')} />
                  <div className="tw-max-h-[700px] tw-overflow-auto tw-flex tw-flex-row md:tw-flex-col tw-gap-5 md:tw-gap-0 md:tw-pr-[10px] md:tw-mr-[-10px] md:tw-mb-[10px]" id="products-scroll">
                    {data?.map((products, index) => {
                      return (
                        <div style={{
                          paddingTop: products.paddingTopBottom ? products.paddingTopBottom + 'px' : '32px',
                          paddingBottom: products.paddingTopBottom ? products.paddingTopBottom + 'px' : '32px'
                        }} className={`tw-max-h-[90px] tw-relative tw-min-w-full tw-bg-[#FFFFFF] tw-rounded-[8px] tw-mt-[15px] tw-cursor-pointer tw-border-[3px] ${active === index ? " tw-border-[#485FC7]" : "  tw-border-[#FFFFFF]"} `} key={index}
                          onClick={() => setActive(index)}
                          onMouseEnter={() => setHoverState(index)}
                        >
                          <img style={{
                          maxHeight: products.maxHeight ? products.maxHeight + 'px' : '25px',
                        }} src={products?.image} className={`tw-m-auto ${index === hoverState || index === active ? 'tw-block' : 'tw-hidden'}`} />
                          {products?.greyImage && <img style={{
                          maxHeight: products.maxHeight ? products.maxHeight + 'px' : '25px',
                        }} src={products?.greyImage} className={`tw-m-auto ${index !== hoverState && index !== active ? 'tw-block' : 'tw-hidden'}`} />}

                          <div className={`${active === index ? "tw-hidden tw-bg-[#485FC7] tw-w-[20px] tw-h-[20px] tw-rounded-[20px] md:tw-flex tw-items-center tw-justify-center tw-absolute tw-right-[-12px] tw-top-[34px]" : ""} `}>
                            <img src={angleRightSapphire} className="tw-max-w-[8px] " />
                          </div>
                        </div>
                      )
                    })}

                  </div>
                  {(openAISuggestedProducts.length >= 1 && openAISuggestedProducts.length < 8) && (<div class=" tw-whitespace-nowrap tw-bg-[#485FC7] tw-p-[12px_24px] tw-rounded-[12px] tw-cursor-pointer hover:tw-bg-accruent_sapphire"><button class="tw-text-[#FFFFFF] tw-text-[16px] tw-font-bold tw-font-Poppins " onClick={getAllProducts}>Show All Products</button></div>)}
                  <img className="md:tw-hidden tw-max-h-[15px]" src={angleRight} onClick={() => scrollMethod('right')} />
                </div>
                {data?.length > 6 &&
                  <img onClick={() => scrollMethod('down')} src={angleDownSapphire} className="md:tw-block tw-hidden tw-cursor-pointer svg-inline--fa fa-angle-up angle-icon tw-angle-icon tw-max-h-[15px]" decoding='async' />}

              </div>
            </div>
            <div class="tw-w-full md:tw-w-3/4 tw-z-10">
              <div>
                <p className="tw-mb-[20px] md:tw-min-h-[40px]">{data?.[active]?.iframeText}</p>
              </div>
              <div className="tw-rounded-[20px] tw-bg-white tw-z-[10] !tw-p-0 content_img tw-box-shadow-demo">
                <div className="story-lane sl-embed ">
                  <StoryLane storyLane={data[active]?.storyLane} />
                </div>
              </div>

              <div className="cta-reviews tw-mb-[70px] md:tw-mb-0 tw-flex tw-flex-col-reverse md:tw-flex-row tw-mt-[16px] tw-rounded-[20px] tw-bg-[#E6E7E8] tw-relative tw-items-center">
                {data?.[active]?.reviews?.length && <div className="tw-w-full md:tw-w-1/4 ">
                  <div className="tw-p-[24px_48px_24px_48px] review-img">
                    {data?.[active]?.reviews?.map((review, index) => {
                      return (
                        <p key={index}><a href={review?.anchorLink}><img src={review?.imageLink} /></a></p>
                      )
                    })}
                  </div>
                </div>}
                {data?.[active]?.reviews?.length && <div className={`tw-w-full md:tw-w-3/4 tw-bg-[#FFFFFF] tw-rounded-[0px_20px_20px_0px] ${data?.[active]?.reviews?.length ? "" : '!tw-w-full  tw-justify-center tw-items-center md:tw-flex tw-rounded-[20px]'}`}>
                  <div className="tw-p-[42px_32px_42px_32px] tw-flex tw-flex-col md:tw-flex-row tw-items-center tw-gap-[30px]">
                    <div className="md:tw-block tw-flex tw-flex-col tw-items-center tw-text-center md:tw-text-left md:tw-min-w-[360px]">
                      <img src={data[active]?.image} className="tw-max-h-[35px] -md:tw-max-w-[100px]" />
                      <p className="tw-mt-[16px]">{data[active]?.ctaText}</p>
                    </div>
                    <div className="tw-flex tw-flex-col tw-gap-4 tw-items-center">
                      <RequestDemoBtn setShowModal={setShowModal} />
                      <LocalizedLink to={'/calendar-scheduling'} className="tw-text-[#001C71] tw-font-bold tw-text-[16px] tw-font-Poppins hover:tw-text-[#001C71] hover:tw-underline">{'Schedule a Call >'}</LocalizedLink>
                    </div>
                  </div>
                </div>}


                {!data?.[active]?.reviews?.length && <div className={`tw-w-full tw-bg-[#FFFFFF] tw-rounded-[20px]`}>
                  <div className="tw-p-[42px_32px_42px_32px] tw-flex tw-flex-col md:tw-flex-row tw-items-center tw-gap-[30px] md:tw-justify-between">
                    <div className="tw-flex tw-flex-col tw-items-center tw-text-center md:tw-text-left  md:tw-items-baseline ">
                      <img src={data[active]?.image} className="tw-max-h-[35px] -md:tw-max-w-[100px]" />
                      <p className="tw-mt-[16px]">{data[active]?.ctaText}</p>
                    </div>
                    <div className="tw-flex tw-flex-col tw-gap-4 tw-items-center">
                      <RequestDemoBtn setShowModal={setShowModal} />
                      <LocalizedLink to={'/calendar-scheduling'} className="tw-text-[#001C71] tw-font-bold tw-text-[16px] tw-font-Poppins hover:tw-text-[#001C71] hover:tw-underline">{'Schedule a Call >'}</LocalizedLink>
                    </div>
                  </div>
                </div>}

              </div>

            </div>
            <div>

            </div>

          </div>
          <div className="tab-footer tw-mb-[-100px]">
            <div className="full-width-bg-cover tw-bg-accruent_sapphire tw-mt-[-501px] tw-min-h-[551px]">
              <div className="footer_content tw-w-full tw-flex tw-flex-col tw-justify-center tw-items-center tw-pt-[190px] tw-pb-[95px]">
              </div>
            </div>
          </div>


        </div>}
        <div className={`${!showModal ? ' tw-hidden' : ' tw-block'}`}>
          <FormModal
            formTag={formTag}
            handleToggle={handleToggle}
            isFormLoaded={isFormLoaded}
          />
        </div>
      </div>
    </div>
  )
}
export default DemoNew;
